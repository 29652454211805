<template>
  <div v-if="statistiekenData.length">
    <UITableResponsive :items="statistiekenData || []" class="text-center w-auto" :headers="headers">
      <template #colgroup>
        <colgroup>
          <col v-for="header in headers" :key="header" :class="header === headers[headers.length - 1] ? 'w-auto' : 'w-32'">
        </colgroup>
      </template>
      <template #item-Naam="{ item }">
        <small v-if="!item.user_id" class="text-red-500">Geen chauffeur</small>
        <span v-else>{{ store.getters.chauffeur(item.user_id)?.label || item.user_id }}</span>
      </template>
      <template #item-Met="{ item }">
        <span class="font-bold text-green-500">{{ millisecondsToHMSWritten(item.met) }}</span>
      </template>
      <template #item-Zonder="{ item }">
        <span class="font-bold text-red-500">{{ millisecondsToHMSWritten(item.zonder) }}</span>
      </template>
      <template #item-Totaal="{ item }">
        <span class="font-bold text-blue-500">{{ millisecondsToHMSWritten(item.met + item.zonder) }}</span>
      </template>
    </UITableResponsive>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import UITableResponsive from '@/components/UI/Table/Responsive'

import { millisecondsToHMSWritten } from '@/functions/formatDate'
import useGetApi from '@/hooks/useGetApi'

const props = defineProps({
  filters: {
    type: Object,
    required: true,
  },
})

const store = useStore()
const headers = [
  'Naam',
  store.getters.hasMasterPermission && 'Met',
  store.getters.hasMasterPermission && 'Zonder',
  'Totaal',
].filter(Boolean)

const { data } = useGetApi('/api/dashboard/prestaties/list', props.filters, { watch: true })

const statistiekenData = computed(() => {
  return Object.entries(data.value || {}).map(([user_id, data]) => {
    return {
      user_id,
      ...data.month,
    }
  }).sort((a, b) => {
    return (b.met + b.zonder) - (a.met + a.zonder)
  })
})
</script>
